ejs-grid {
    @apply shadow overflow-hidden border-b border-gray-200 sm:rounded-lg font-sans;
}


table.e-table {
    @apply min-w-full divide-y border-gray-200;
}

th.e-headercell {
    @apply px-6 py-3 bg-gray-50 tracking-wider border-none;
}

.e-headercelldiv {
    @apply text-xs text-gray-500 uppercase;
}

.e-headertext {
    @apply uppercase;
    font-size: 11px;
    margin-left: 10px;
}

table.e-table tbody {
    @apply bg-white;
}

table.e-table tr {
  @apply cursor-pointer;  
}

td.e-rowcell {
    @apply px-6 py-2 whitespace-nowrap text-sm leading-5 text-gray-500;
    margin-left: 10px;
}

.e-pagenomsg, .e-pagecountmsg {
    @apply text-sm leading-5 text-gray-700;
}

.e-pagercontainer {
    @apply border-none;
}

.e-numericcontainer .e-numericitem {
    @apply bg-white mt-px border-t-2 border-r-0 rounded-none border-transparent pt-2 px-4 items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400;

    &.e-active {
        @apply -mt-px font-bold border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700;
    }

    &:hover {
        @apply pt-2 px-4 #{!important};
    }
}

.e-pagercontainer .e-first, .e-prev, .e-next, .e-last {
    @apply bg-white px-2 text-indigo-600 text-xs border-none focus:outline-none focus:text-indigo-800 focus:border-indigo-700;

    &.e-disable {
        @apply text-gray-600;
    }
}

.e-pager .e-currentitem, .e-pager .e-currentitem:hover {
    @apply bg-white mt-px border-t-2 border-r-0 rounded-none pt-2 px-4 items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 #{!important};

    &:hover {
        @apply pt-2 px-4;
    }
}

.e-gridcontent {
    min-height: 200px !important;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    margin-top: -3px;
}
