.form-control {
  border-color: #ccc !important;
  @apply shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md #{!important};
}

input.form-control {
  height: 40px !important;
}

.form-group {
  @apply block mb-5;
}

.label {
  @apply text-gray-500 font-bold pb-2;
}

.error-message {
  @apply hidden;
}

.error {
  .form-control {
    @apply border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red-500;
  }

  .label {
    @apply text-red-600;
  }

  .error-message {
    @apply block mt-2 text-sm text-red-600;
  }
}

.form-checkbox {
  @apply focus:ring-indigo-500 text-indigo-600 border-gray-300 rounded;
}