.badge {
  @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;

  &.badge-primary {
    @apply bg-blue-100 text-blue-800;
  }

  &.badge-warning {
    @apply bg-yellow-100 text-yellow-800;
  }

  &.badge-success {
    @apply bg-green-100 text-green-800;
  }

  &.badge-danger {
    @apply bg-red-100 text-red-800;
  }
}
