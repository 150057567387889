@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'animate.css/animate.min.css';

@import "../node_modules/@syncfusion/ej2/bootstrap5.css";
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/bootstrap5.css';

@import "@ng-select/ng-select/themes/default.theme.css";

@import "./styles/buttons.scss";
@import "./styles/badges.scss";
@import "./styles/form.scss";
@import "./styles/ej2-buttons.scss";
@import "./styles/ej2-datepicker.scss";
@import "./styles/ej2-grid.scss";

.d-none {
    display: none !important;
}

input[type="radio"]:disabled {
    display: block !important;
}

// Workaround, because template columns get hidden when the user searches in a grid
.e-templatecell.e-hide, .e-headercell.e-hide.e-defaultcursor {
    display: table-cell !important;
}

.e-headercelldiv {
    padding: 0px !important;
    font-size: 0.60rem !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 42px !important;
}

.ng-select .ng-select-container {
    min-height: 42px !important;
}

input.e-input, .e-input-group input, .e-input-group.e-control-wrapper input, .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
    height: 40px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 10px !important;
}

.e-pagerdropdown {
    margin-top: -30px !important;
    overflow: visible !important;
}

.e-cc-searchdiv.e-cc.e-input-group, .e-ccsearch-icon.e-icons.e-cc.e-input-group-icon{
    height: 34px !important;
}

.e-ccsearch-icon.e-icons.e-cc.e-input-group-icon {
    padding: 0 !important;
}

.e-gridcontent {
    min-height: 400px !important; 
}