.btn {
  @apply inline-flex items-center px-4 py-2 border text-base leading-6 font-medium rounded-md transition ease-in-out duration-150;
  @apply focus:outline-none;

  &.btn-default {
    @apply border-gray-300 text-gray-700 bg-gray-200;
    @apply hover:text-gray-500 hover:border-gray-300 hover:bg-gray-300;
    @apply focus:border-blue-300;
    @apply active:text-gray-800 active:bg-gray-50;
  }

  &.btn-white {
    @apply bg-white;
    @apply hover:bg-gray-100;
  }

  &.btn-primary {
    @apply text-white bg-indigo-600;
    @apply hover:bg-indigo-500 active:bg-indigo-700 focus:border-indigo-700;

    &:disabled {
      @apply bg-indigo-200;
    }
  }

  &.btn-primary-outline {
    @apply border-indigo-600 bg-white text-indigo-600 hover:bg-indigo-100;

    &:disabled {
      opacity: 0.5;
    }
  }

  &.btn-secondary {
    @apply text-indigo-700 bg-indigo-100 border-indigo-100;
    @apply hover:bg-indigo-50 hover:border-indigo-50 focus:outline-none focus:border-indigo-300 focus:ring-indigo-500 active:bg-indigo-200;

    &:disabled {
      @apply bg-indigo-200;
    }
  }

  &.btn-info {
    @apply text-white bg-blue-700 border-blue-800;
    @apply hover:bg-blue-600 hover:border-blue-700 focus:outline-none focus:border-blue-300 focus:ring-blue-500 active:bg-yellow-600;

    &:disabled {
      @apply bg-blue-200;
    }
  }

  &.btn-warning {
    @apply text-white bg-yellow-700 border-yellow-800;
    @apply hover:bg-yellow-600 hover:border-yellow-700 focus:outline-none focus:border-yellow-300 focus:ring-yellow-500 active:bg-yellow-600;

    &:disabled {
      @apply bg-yellow-200;
    }
  }

  &.btn-danger {
    @apply text-white bg-red-700 border-red-800;
    @apply hover:bg-red-600 hover:border-red-700 focus:outline-none focus:border-red-300 focus:ring-red-500 active:bg-red-600;

    &:disabled {
      @apply bg-red-200;
    }
  }

  &.btn-danger-outline {
    @apply border-red-400 bg-white text-red-700 hover:bg-red-100;

    &:disabled {
      opacity: 0.5;
    }
  }
}